.Credits {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inconsolata", monospace;
  margin: 20px 10px 20px 10px;
}

.SimpleLink {
  color: inherit;
}
