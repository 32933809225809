.Info {
  background-color: var(--bg-text-color);
  margin: 1.5em 1em 0 1em;
  padding: 1.2em;
  border-radius: 10px;
  font-size: 0.9em;
  max-width: 800px;
}

@media screen and (min-width: 800px) {
  .Info {
    margin: 1.5em 2em 0 2em;
  }
}

.Info h2 {
  text-align: center;
  margin: 0;
  font-size: 1.2em;
}

.Info ul {
  padding-left: 1em;
}

.Info a {
  color: var(--bg-color);
}

.ExampleComicImage {
  max-height: 200px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
