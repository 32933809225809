.Translation {
  --margin-amount: 25px;
  background-color: var(--bg-text-color);
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--margin-amount) var(--margin-amount) 0px var(--margin-amount);
  width: calc(100% - (2 * var(--margin-amount)));
  min-height: 200px;
}

@media only screen and (max-width: 800px) {
  .Translation {
    margin: 0px;
    width: 100%;
    min-height: 300px;
  }
  .TranslationBoxes {
    flex-direction: column;
  }
  .TranslationOutput {
    background-color: #538766;
    color: white;
    border-bottom: 2px solid #467e5b;
  }
}

@media only screen and (min-width: 801px) {
  .Translation {
    border-radius: 10px;
  }
  .TranslationInput {
    border-right: 1px solid #ccc;
  }
}

.TranslationDescription {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}

.TranslationDescription > * {
  display: flex;
  padding: 10px;
}

.TranslationBoxes {
  display: flex;
  width: 100%;
  flex-grow: 1;
}

.TranslationBox {
  padding: 20px;
  flex-grow: 1;
  position: relative;
}
