.App {
  background-color: var(--bg-color);
  font-family: var(--normal-font-family);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: var(--font-color);
}
